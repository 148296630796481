/* eslint-disable react/prop-types */
import React from 'react';

import './info.scss';

const Services = (props) => (
  <div>
    <div className="my-3 info-wrapper d-lg-flex col-lg-8 mx-auto">
      <div className="col left-content">
        <div className="d-flex">
          <p className="title">{`${props.servicesData.data.secretary}: `}</p>
        </div>
        <div className="d-flex flex-column">
          <p className="title-content-head">
            {props.servicesData.data.secretaryName}
          </p>
          <a
            className="title-content-head"
            href={`mailto:${props.servicesData.data.secretaryMail}`}
          >
            {`${props.servicesData.data.email}: ${props.servicesData.data.secretaryMail}`}
          </a>
          <a
            className="title-content-head"
            href={`tel:${props.servicesData.data.secretaryPhone}`}
          >
            {`${props.servicesData.data.tel}: ${props.servicesData.data.secretaryPhone}`}
          </a>
        </div>
        <div className="d-flex">
          <p className="title">{`${props.servicesData.data.deansOffice}:`}</p>
        </div>
        <div className="d-flex flex-column">
          <p className="title-content-head">
            {props.servicesData.data.deansOfficeName}
          </p>
          <a
            className="title-content-head"
            href={`mailto:${props.servicesData.data.deansOfficeMail}`}
          >
            {`${props.servicesData.data.email}: ${props.servicesData.data.deansOfficeMail}`}
          </a>

          <a
            className="title-content-head"
            href={`tel:${props.servicesData.data.deansOfficePhone}`}
          >
            {`${props.servicesData.data.tel}: ${props.servicesData.data.deansOfficePhone}`}
          </a>
        </div>
        <div className="d-flex">
          <p className="title">{`${props.servicesData.data.studentService}:`}</p>
        </div>
        <div className="d-flex flex-column">
          <p className="title-content-head">
            {props.servicesData.data.studentServiceName}
          </p>
          <a
            className="title-content-head"
            href={`mailto:${props.servicesData.data.studentServiceMail}`}
          >
            {`${props.servicesData.data.email}: ${props.servicesData.data.studentServiceMail}`}
          </a>
          <a
            className="title-content-head"
            href={`tel:${props.servicesData.data.studentServicePhone}`}
          >
            {`${props.servicesData.data.tel}: ${props.servicesData.data.studentServicePhone}`}
          </a>
        </div>
        <div className="d-flex">
          <p className="title">{`${props.servicesData.data.maintenanceService}:`}</p>
        </div>
        <div className="d-flex flex-column">
          <p className="title-content-head">
            {props.servicesData.data.maintenanceServiceName}
          </p>
          <a
            className="title-content-head"
            href={`mailto:${props.servicesData.data.maintenanceServiceMail}`}
          >
            {`${props.servicesData.data.email}: ${props.servicesData.data.maintenanceServiceMail}`}
          </a>
          <a
            className="title-content-head"
            href={`tel:${props.servicesData.data.maintenanceServicePhone}`}
          >
            {`${props.servicesData.data.tel}: ${props.servicesData.data.maintenanceServicePhone}`}
          </a>
        </div>
      </div>
      <div className="col right-content">
        <div className="d-flex">
          <p className="title ms-lg-3">{`${props.servicesData.data.financialService}:`}</p>
        </div>
        <div className="d-flex flex-column">
          <p className="title-content-head ms-lg-3">
            {props.servicesData.data.financialServiceName}
          </p>
          <a
            className="title-content-head ms-lg-3"
            href={`mailto:${props.servicesData.data.financialServiceMail}`}
          >
            {`${props.servicesData.data.email}: ${props.servicesData.data.financialServiceMail}`}
          </a>
          <a
            className="title-content-head ms-lg-3"
            href={`tel:${props.servicesData.data.financialServicePhone}`}
          >
            {`${props.servicesData.data.tel}: ${props.servicesData.data.financialServicePhone}`}
          </a>
        </div>
        <div className="d-flex">
          <p className="title ms-lg-3">{`${props.servicesData.data.humanResources}:`}</p>
        </div>
        <div className="d-flex flex-column">
          <p className="title-content-head ms-lg-3">
            {props.servicesData.data.humanResourcesName}
          </p>
          <a
            className="title-content-head ms-lg-3"
            href={`mailto:${props.servicesData.data.humanResourcesMail}`}
          >
            {`${props.servicesData.data.email}: ${props.servicesData.data.humanResourcesMail}`}
          </a>
          <a
            className="title-content-head ms-lg-3"
            href={`tel:${props.servicesData.data.humanResourcesPhone}`}
          >
            {`${props.servicesData.data.tel}: ${props.servicesData.data.humanResourcesPhone}`}
          </a>
        </div>
        <div className="d-flex">
          <p className="title ms-lg-3">{`${props.servicesData.data.ictCenter}:`}</p>
        </div>
        <div className="d-flex flex-column">
          <p className="title-content-head ms-lg-3">
            {props.servicesData.data.ictCenterName}
          </p>
          <a
            className="title-content-head ms-lg-3"
            href={`mailto:${props.servicesData.data.ictCenterMail}`}
          >
            {`${props.servicesData.data.email}: ${props.servicesData.data.ictCenterMail}`}
          </a>
          <a
            className="title-content-head ms-lg-3"
            href={`tel:${props.servicesData.data.ictCenterPhone}`}
          >
            {`${props.servicesData.data.tel}: ${props.servicesData.data.ictCenterPhone}`}
          </a>
        </div>
        <div className="d-flex">
          <p className="title ms-lg-3">ZAPRIMANJE AKATA: </p>
        </div>
        <div className="d-flex flex-column">
          <p className="title-content-head ms-lg-3">
            Elektronska pošta za zaprimanje akata:
          </p>
          <a
            className="title-content-head ms-lg-3"
            href="mailto:protokol@etf.unsa.ba"
          >
            protokol@etf.unsa.ba
          </a>
          <p className="title-content-head ms-lg-3">
            Konvencionalni način zaprimanja akata:
            <br />Protokol Univerzitet u Sarajevu – Elektrotehničkog fakulteta, soba 1-26, prvi sprat
            <br />Kampus Univerziteta u Sarajevu
            <br />Zmaja od Bosne bb,
            <br />71000 Sarajevo
            <br />Bosna i Hercegovina
          </p>
        </div>



      </div>
    </div>
    <hr className="col-lg-8 mx-auto" />
  </div>
);
export default Services;
