/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import emailjs from 'emailjs-com';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { MdOutlineLocalPostOffice, MdOutlineLocalPhone } from 'react-icons/md';
import { VscLocation } from 'react-icons/vsc';

import './contact.scss';

const schema = yup.object({
  email: yup
    .string()
    .email('Unesite ispravan email')
    .required('Email je obavezan'),
});

const Contact = (props) => {
  const [successText, setSuccessText] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const sendEmail = () => {
    emailjs.sendForm(
      'service_p7geqe7',
      'template_oj7tx4h',
      '#contact-form',
      '4HzY0VPICfup2KS8M',
    );
    setSuccessText(true);
    reset();
    setTimeout(() => {
      setSuccessText(false);
    }, 3000);
  };

  return (
    <div>
      <div className="wrapper container-fluid col-lg-7 d-lg-flex">
        <div className="left-col col-lg-6 mx-auto">
          <div className="col-12 left-col-container">
            <h3>{props.data.title}</h3>
            <div className="flex-container d-flex">
              <div className="col-2 small-left-container">
                <div className="icons-container  d-flex flex-column">
                  <VscLocation className="fa-solid fa-location-dot text-center my-auto mx-auto" />
                  <MdOutlineLocalPhone className="fa-solid fa-phone text-center my-auto mx-auto" />
                  <MdOutlineLocalPostOffice className="fa-solid fa-envelope text-center my-auto mx-auto" />
                </div>
              </div>
              <div className="col-9 big-left-container ms-3">
                <div className="location">
                  <p className="location-title">{props.data.location}</p>
                  <p className="location-content">{props.data.address}</p>
                </div>
                <div className="telephone">
                  <p className="telephone-title">{props.data.phone}</p>
                  <a className="tel_fax" href={`tel: ${props.data.tel}`}>
                    {props.data.tel}
                  </a>
                  <br />
                  <a className="tel_fax" href={`fax: ${props.data.fax}`}>
                    {props.data.fax}
                  </a>
                </div>
                <div className="mail">
                  <p className="mail-title">{props.data.email}</p>
                  <a className="email" href={`mailto: ${props.data.mail}`}>
                    {props.data.mail}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
Contact.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    mail: PropTypes.string.isRequired,
    tel: PropTypes.string.isRequired,
    fax: PropTypes.string.isRequired,
    contactForm: PropTypes.shape({
      name: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      message: PropTypes.string.isRequired,
      submit: PropTypes.string.isRequired,
      sent: PropTypes.string.isRequired,
      placeholderName: PropTypes.string.isRequired,
      placeholderLastName: PropTypes.string.isRequired,
      placeholderEmail: PropTypes.string.isRequired,
      placeholderMessage: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default Contact;
